import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Jumbotron from "../components/jumbotron/jumbotron"
import BasicButton from "../components/buttons/BasicButton/BasicButton"
import About from "../components/about/about"
import FillButton from "../components/buttons/FillButton/FillButton"
import logo from "../images/logo.png"
import SEO from "../components/seo"
import Perks from "../components/perks/perks"
import ChooseTreatment from "../components/choose_treatment/choose_treatment"

function IndexPage() {
  const data = useStaticQuery(query)
  const {
    glowne_zdj,
    glowne_zdj_mobile,
    opis_gabinetu,
    zdjecie_opis_gabinetu,
    opis_profesjonalizm,
    opis_oferta,
    opis_zaufanie,
  } = data.strapiStronaGlowna
  const sources = [
    glowne_zdj.childImageSharp.fluid,
    {
      ...glowne_zdj_mobile.childImageSharp.fluid,
      media: `(max-width: 700px)`,
    },
  ]
  return (
    <Layout>
      <SEO
        title="Strona główna"
        description={data.strapiMetaTag.opis_strony}
        keywords={data.strapiMetaTag.tagi}
      />
      <Jumbotron src={sources}>
        <BasicButton to={"/zabiegi/"}>zabiegi</BasicButton>
        <BasicButton to={"/kontakt/"}>kontakt</BasicButton>
      </Jumbotron>

      <About desc={opis_gabinetu} logo={logo} img={zdjecie_opis_gabinetu}>
        <FillButton to={"/o-mnie/"} style={{ fontSize: "2rem" }}>
          o mnie
        </FillButton>
      </About>
      <Perks prof={opis_profesjonalizm} offer={opis_oferta} trust={opis_zaufanie} />
      <ChooseTreatment />
    </Layout>
  )
}

export const query = graphql`
  {
    strapiStronaGlowna {
      opis_gabinetu
      opis_profesjonalizm
      opis_oferta
      opis_zaufanie
      glowne_zdj {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      glowne_zdj_mobile: glowne_zdj {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      zdjecie_opis_gabinetu {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    strapiMetaTag(nazwa_strony: { eq: "strona_glowna" }) {
      opis_strony
      tagi
    }
  }
`

export default IndexPage

import React from "react"
import TrustIcon from "../../images/trust.inline.svg";
import WorkIcon from "../../images/doctor.inline.svg"
import TrendingUpIcon from "../../images/ticklist.inline.svg"
import ReactMarkdown from "react-markdown"
import classes from "./perks.module.scss"
import PerksItem from "./perks_item/perks_item"

const Perks = ({ prof, offer, trust }) => {
  const perks = [
    {
      title: "Profesjonalizm",
      description: (
        <ReactMarkdown
          transformImageUri={uri =>
            uri.startsWith("http") ? uri : `${process.env.IMAGE_BASE_URL}${uri}`
          }
        >{prof}</ReactMarkdown>
      ),
      icon: <WorkIcon style={{fill: "#E1A590"}}/> ,
    },
    {
      title: "Szeroka oferta",
      description: (
        <ReactMarkdown
          transformImageUri={uri =>
            uri.startsWith("http") ? uri : `${process.env.IMAGE_BASE_URL}${uri}`
          }
        >{offer}</ReactMarkdown>
      ),
      icon: (
        <TrendingUpIcon style={{fill: "#E1A590"}}/> 
      ),
    },
    {
      title: "Zaufanie",
      description: (
        <ReactMarkdown
          transformImageUri={uri =>
            uri.startsWith("http") ? uri : `${process.env.IMAGE_BASE_URL}${uri}`
          }
        >{trust}</ReactMarkdown>
      ),
      icon: <TrustIcon  style={{fill: "#E1A590"}}/>,
    },
  ]
  return (
    <section className={classes.perks}>
      <h2 className={classes.title}>DLACZEGO JA?</h2>
      {perks.map(perk => (
        <PerksItem
          title={perk.title}
          icon={perk.icon}
          description={perk.description}
        />
      ))}
      <hr />
    </section>
  )
}

export default Perks

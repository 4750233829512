import React from "react"
import { Link } from "gatsby"
import classes from "./choose_treatment.module.scss"

const ChooseTreatment = () => {
  return <section className={classes.ChooseTreatment}>
      <h2 className={classes.title}>Nie wiesz jaki wybrać zabieg?</h2>
      <Link to="/problem" className={[classes.btn, classes.btn1].join(" ")}>Znajdź rozwiązanie swojego problemu</Link>
      <span className={classes.text}>lub</span>
      <Link to="/kontakt" className={[classes.btn, classes.btn2].join(" ")}>Skontaktuj się ze mną</Link>

  </section>
}

export default ChooseTreatment

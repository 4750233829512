import React from 'react'
import { Link } from 'gatsby'
import style from './BasicButton.module.scss'

function BasicButton(props) {
    return (
        <Link to={props.to} className={style.Button} style={props.style}> {props.children} </Link> 
    )
}

export default BasicButton

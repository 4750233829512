import React from 'react'

import classes from './perks_item.module.scss'; 


const PerksItem = ({title, icon, description}) => {
    return (
        <div className={classes.PerksItem}>
            <span className={classes.icon}>{icon}</span>
            <h3 className={classes.title}>{title}</h3>
            <p className={classes.description}>{description}</p>
        </div>
    )
}

export default PerksItem

import React from "react"
import style from "./about.module.scss"
import BackgroundImage from "gatsby-background-image"

function about(props) {
  return (
    <section className={style.About}>
      <BackgroundImage
        Tag={`div`}
        id={`main-about-image`}
        className={style.Image}
        fluid={props.img.childImageSharp.fluid}
        fadeIn={false}
        loading="eager"
      ></BackgroundImage>

      <div className={style.Container}>
        <div className={style.Content}>
          <h1 className={style.Title}>Medycyna Estetyczna Legnica</h1>
          <h2 className={style.subtitle}>lekarz Piotr Ślusarczyk</h2>
          <h2 className={style.Text}>
            <pre>{props.desc}</pre>
          </h2>
          {props.children}
        </div>
      </div>
    </section>
  )
}

export default about

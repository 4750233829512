import React from "react"
import BackgroundImage from "gatsby-background-image"
import style from "./jumbotron.module.scss"
function Jumbotron(props) {
  return (
    <section className={style.Jumbotron}>
      <BackgroundImage
        fadeIn={false}
        loading="eager"
        Tag={`div`}
        id={`main-jumbotron-image`}
        className={style.Jumbotron__image}
        fluid={props.src}
      >
        <div className={style.Overlay}></div>

        <div className={style.Container}>
          <h2 className={style.MainMotto}>
            DOBRY WYGLĄD <br /> TO ŚWIETNE <br /> SAMOPOCZUCIE{" "}
          </h2>
          <div className={style.Buttons}>{props.children}</div>
        </div>
      </BackgroundImage>
    </section>
  )
}

export default Jumbotron
